@import './src/scss/variables';
@import './src/scss/game';

.grid {
  table-layout: fixed;
  border-collapse: collapse;
  width: fit-content;
  display: block;
  margin: 0 auto;
  font-size: small;

  .hover {
    background-color: #f3f4f7;
  }

  .mouseover {
    background-color: darkgray;
  }

  &.highlight-correct {
    tr td div.number.complete {
      color: green;
      font-weight: bold;
    }

    .grid__columns, .grid__rows {
      *:has(.number) {
        cursor: auto  !important;
      }
    }
  }

  &:not(.highlight-correct) {
    tr td div.number.mark {
      color: blue;
      font-weight: bold;
    }
  }

  .grid__columns {
    border-top: 0 !important;
    border-right: 0 !important;

    .hover {
      background-color: unset;

      &:has(.number) {
        background-color: #f3f4f7;
        cursor: pointer;
      }
    }
  }

  .grid__rows {
    border-left: 0 !important;
    border-bottom: 0 !important;

    .hover {
      background-color: unset;

      td:has(.number) {
        background-color: #f3f4f7;
        cursor: pointer;
      }
    }
  }

  tr {
    td {
      width: 20px;
      height: 20px;
      text-align: center;

      div {
        width: 20px;
        height: 20px;

        //&.number {
        //}
      }
    }
  }

  .grid__columns {
    table {
      tr {
        td.five:not(:last-child) {
          border-color: $color;
        }
      }
    }
  }

  .grid__rows {
    table {
      tr.five:not(:last-child) td {
        border-bottom: 1px solid $color;
      }
    }
  }

  .grid__columns, .grid__rows {
    border: 1px solid;

    table {
      tr {
        td:not(:last-child) {
          border-right: 1px solid $color5;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $color5;
        }
      }
    }
  }

  .grid__cells {
    //border-right: 1px solid;
    //border-bottom: 1px solid;
    cursor: pointer;

    table {
      tr {
        &:not(:last-child) {
          &.five td {
            border-bottom: 1px solid $color;
          }

          border-bottom: 1px solid $color5;
        }

        td {
          &:not(:last-child) {
            &.five {
              border-color: $color
            }

            border-right: 1px solid $color5;
          }

					position: relative;
          width: 20px;
          height: 20px;

          div {
            width: 20px;
            height: 20px;

            &.cross {
              position: relative;

              &:before {
                font-family: 'Font Awesome 6 Pro';
                font-weight: 900;
                content: "\f00d";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 2px;
                left: 0;
                text-align: center;
                color: $color11;
                font-size: 16px;
              }
            }

            &.black {
              //background: #000;

              &:before {
                font-family: 'Font Awesome 6 Pro';
                font-weight: 900;
                content: "\f111";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 2px;
                left: 0;
                text-align: center;
                color: $color;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}